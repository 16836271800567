<template>
    <div class="font">
        <ayl-berad-nav :nav="nav" />
        <div class="content-box mt20px">
            <div class="content-main">
                <ayl-table-with-query-object :table="table" @selection-change="handleSelectionChange">
                    <div slot="ctrl-button">
                        <el-button class="btn" style="margin-left: 20px" @click="onAudit">处理</el-button>
                    </div>
                </ayl-table-with-query-object>
            </div>
        </div>
        <el-dialog
            title="处理结果"
            :visible.sync="auditDialogVisible"
            width="30%"
            center>
            <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition"
                    :label-width="$config.labelWidth">
                <el-form-item label="处理结果" style="width: 100%">
                    <el-radio v-model="form.radio" label="2">通过</el-radio>
                    <el-radio v-model="form.radio" label="3">不通过</el-radio>
                </el-form-item>
                <el-form-item label="处理意见" style="width: 100%">
                    <el-input v-model="form.opinion" placeholder="请输入" maxlength="25"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onDetermine">确 定</el-button>
                <el-button @click="auditDialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            // eslint-disable-next-line no-unused-vars
            const vm = this
            return {
                nav: [{
                    name: "申诉管理"
                }],
                auditDialogVisible: false,
                table: {
                    api: vm.$api_hw.complainManage_query,
                    query: {
                        sanitationComplainManageVO: {
                            complainObjectType: null,
                            bidId: null,
                            gridId: null,
                            routeId: null,
                            complainReason: null,
                            checkStatus: null,
                        }
                    },
                    // 是否显示分页
                    // hidePagination: true,
                    // 表格查询的条件组件
                    searchData: [{
                            type: 'select',
                            title: '申诉对象',
                            model: 'sanitationComplainManageVO.complainObjectType',
                            placeholder: '请选择',
                            option: vm.$enums_hw.complainObjectType.list
                        }, {
                            type: "cascader_radio",
                            title: "所属标段",
                            model: "sanitationComplainManageVO.bidId",
                            placeholder: "请选择",
                            option: [],
                            width: "180px",
                            flat: []
                        }, {
                            type: 'select',
                            title: '所属网格',
                            model: 'sanitationComplainManageVO.gridId',
                            placeholder: '请选择',
                            option: [],
                        }, {
                            type: 'select',
                            title: '所属路线',
                            model: 'sanitationComplainManageVO.routeId',
                            placeholder: '请选择',
                            option: []
                        }, {
                            type: 'select',
                            title: '不合格原因',
                            model: 'sanitationComplainManageVO.complainReason',
                            placeholder: '请选择',
                            option: vm.$enums_hw.nonconformityType.list
                        },
                        {
                            type: 'select',
                            title: '状态',
                            model: 'sanitationComplainManageVO.checkStatus',
                            placeholder: '请选择',
                            option: vm.$enums_hw.checkStatusType.list
                        }, 
                    ],
                    columns: [{
                            type: 'selection',
                            width: '50px'
                        },
                        {
                            title: '序号',
                            width: '50px',
                            $index: 'index',
                        },
                        {
                            title: '申诉时间',
                            key: 'gmtCreate',
                            width: '180px',
                            filter: 'str2ymdhm',
                        }, {
                            title: '申诉对象',
                            key: 'complainObjectName',
                        }, {
                            title: '申诉人员/车辆',
                            
                            render(h, ctx) {
                                return h('span', [
                                    h('span', ctx.row.complainObjectType === 0 ? '人员' : '车辆'),
                                ])
                            }
                        }, {
                            title: '所属标段',
                            key: 'bidName',
                        }, {
                            title: '所属网格/线路',
                            showTooltip: true,
                            key: 'workName',
                        }, {
                            title: '排班日期',
                            width: '100px',
                            key: 'complainScheduleDate',
                        }, {
                            title: '排班时段',
                            width: '100px',
                            key: 'complainScheduleTime',
                        }, {
                            title: '不合格原因',
                            width: '100px',
                            key: 'complainReason',
                        }, {
                            title: '情况说明',
                            width: '100px',
                            key: 'complainObjectTypeDes',
                        }, {
                            title: '状态',
                            width: '190px',
                            showTooltip: true,
                            key: 'complainExplainDes',
                        },
                    ]
                },

                form: {
                    radio: null,
                    opinion: null,
                },
                selectionData: [],
                rules: {},
            }
        },

        methods: {
            //处理
            async onAudit() {
                if(this.selectionData.length === 0) {
                    this.$message.error('请先勾选需处理的数据!');
                    return 
                } else {
                    this.auditDialogVisible = true
                }   
            },
            //勾线数据
            handleSelectionChange(val) {
                this.selectionData = val.map( e =>{
                    return e.complainId
                })
            },
            //确定
            async onDetermine() {
                if(this.form.radio === null) {
                    this.$message.error('请选择处理结果!');
                    return
                } else {
                    await this.$api_hw.complainManage_audit({
                        checkStatus: this.form.radio,
                        checkResult: this.form.opinion,
                        complainIds: this.selectionData,
                    })
                    this.auditDialogVisible = false
                    await this.$search(this.table)
                } 
            },      
        },
        async mounted() {
            await this.$search(this.table)
            this.table.searchData[1].option = await this.$api_hw.common_getBidNameAndIdList({});
            this.table.searchData[2].option = await this.$api_hw.common_getDistinctGrid({});
            this.table.searchData[3].option = await this.$api_hw.common_getDistinctRoute({});
        }
    }
</script>
<style lang='sass' scoped>
.btn
  height: 28px
  border: 1px solid $-color-primary-3
  border-radius: 4px
  color: $-color-primary-3
/deep/ .el-button--primary 
    color: #FFFFFF
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-radio__input.is-checked .el-radio__inner
    border-color: $-color-primary-3
    background: $-color-primary-3
/deep/.el-radio__input.is-checked + .el-radio__label 
    color: $-color-primary-3
/deep/.el-checkbox__input.is-checked .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__inner:hover 
    border-color: $-color-primary-3
.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px
</style>